import Menu from "./sections/Menu";
import meta from "./sections/Meta";
import Footer from "./sections/Footer";
import Games from "./sections/Games";
import JumpAboard from "./sections/JumpAboard";
import LifeAtSocialpoint from "./sections/LifeAtSocialpoint";
import WhatWeDo from "./sections/WhatWeDo";
import StayUpdated from "./sections/StayUpdated";
import News from "./sections/News";
const data = {
  meta,
  menu: Menu(0),
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "purple"
      },
      components: [
        {
          name: "HeaderSlider",
          props: {
            items: [
              {
                id: "13",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/carrousel_13.png",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/TT-logo.png",
                title: "Top Troops!",
                ctaText: "Game details",
                hrefTo: "/games/top-troops",
                backgroundMobile: {
                  url:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/top-troops/carrousel_portrait_13.png"
                }
              },
              {
                id: "2",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/home/carrousel/carrousel_2.jpg",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/DC-logo-2022-2.png",
                title: "Dragon City",
                ctaText: "Game details",
                hrefTo: "/games/dragon-city",
                backgroundMobile: {
                  url:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/home/carrousel/carrousel_portrait_2.jpg"
                }
              },
              {
                id: "12",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/TD_header-banner_BbdayShip_1340x864.jpg",
                // ---- temporarily removing TD logo URL(//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/TD-logo.png) to hide it ----
                logo: "",
                title: "Two Dots",
                ctaText: "Game details",
                hrefTo: "/games/two-dots",
                backgroundMobile: {
                  url:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/two-dots/TD_header-banner_BbdayShip_960x1270.jpg"
                }
              },
              {
                id: "3",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/ML-10_1340x864.png",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/ML-logo-2021.png",
                title: "Monster Legends",
                ctaText: "Game details",
                hrefTo: "/games/monster-legends",
                backgroundMobile: {
                  url:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/games/monster_legends/ML-10_960x1270.png"
                }
              },
              {
                id: "7",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/home/carrousel/carrousel_9.jpg",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/WL-logo.png",
                title: "Word Life",
                ctaText: "Game details",
                hrefTo: "/games/word-life",
                backgroundMobile: {
                  url:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/home/carrousel/carrousel_portrait_9.jpg"
                }
              },
              {
                id: "6",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/home/carrousel/carrousel_6.jpg",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/TT-logo.png",
                title: "Tasty Town",
                ctaText: "Game details",
                hrefTo: "/games/tasty-town",
                backgroundMobile: {
                  url:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/home/carrousel/carrousel_portrait_6.jpg"
                }
              },
              {
                id: "9",
                background:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/home/carrousel/carrousel_7.jpg",
                logo:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logos/WC-logo.png",
                title: "World Chef",
                ctaText: "Game details",
                hrefTo: "/games/world-chef",
                backgroundMobile: {
                  url:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/home/carrousel/carrousel_portrait_7.jpg"
                }
              }
            ],
            arrows: {
              left:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/slider-left.svg",
              right:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/slider-right.svg"
            },
            arrowsAltText: {
              leftAltText: "",
              rightAltText: ""
            }
          }
        },
        Games,
        WhatWeDo,
        {
          name: "Video",
          props: {
            src: "https://vimeo.com/980419093",
            poster:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/home/what-we-do/home-video-preview.jpg",
            player: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
              backgroundColor: "purple",
              alt: "",
              color: "white"
            }
          }
        },
        {
          name: "Separator",
          props: {
            topColor: "purple",
            bottomColor: "gray"
          }
        }
      ]
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [JumpAboard]
    },
    {
      name: "Separator",
      props: {
        topColor: "gray",
        bottomColor: "green"
      }
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "green"
      },
      components: [
        LifeAtSocialpoint,
        {
          name: "Separator",
          props: {
            topColor: "green",
            bottomColor: "red",
            image: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/home/home_1.png",
              alt: "",
              position: "left",
              top: "-top-40 md:-top-500"
            }
          }
        }
      ]
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "red"
      },
      components: [News]
    },
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [
        {
          name: "Separator",
          props: {
            topColor: "red",
            bottomColor: "gray"
          }
        },
        StayUpdated,
        Footer
      ]
    }
  ]
};

export default data;